import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Partners from '../components/Partners';



function Partner() {
  return (
    <>
      <Navbar />
        <Partners/>
      <Footer />
     </>
  );
}

export default Partner;