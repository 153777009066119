import React from 'react'

export default function MainBanner() {
  return (
    <>
    <section className="section main-banner" id="top" data-section="section1">
      <video autoPlay muted loop id="bg-video">
          <source src="https://dl49j1ypakcw5.cloudfront.net/cocfinal.mp4" type="video/mp4" />
      </video>

      <div className="video-overlay header-text">
          <div className="caption">
            <h2></h2>
              <h4></h4>
  
          </div>
      </div>
  </section>
  <section class="features">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-12">
        <div class="features-post">
          <div class="features-content">
            <div class="content-show">
              <h4>Date: 21–24 July 2024</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="features-post second-features">
          <div class="features-content">
            <div class="content-show">
            <i class="fa-solid fa-map-location-dot"></i>
              <h4>Venue: Elephant Hills, Victoria Falls</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="features-post third-features">
          <div class="features-content">
            <div class="content-show">
              <h4><i class="fa fa-clock"></i>Time: 09:00-17:00 CAT</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</>
  )
}
