import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Agenda from '../components/Agenda';
import PrevVideos from '../components/PrevVideos';
import Speakers from '../components/Speakers';


function Speaker() {
  return (
    <>
      <Navbar />
      <Speakers />
      <Footer />
     </>
  );
}

export default Speaker;