import React from 'react'

export default function Recap() {
  return (
    <section class="section video" data-section="section5">
    <div class="container">
    <div class="section-heading">
                    <h2>RECAP FROM LAST CONFERENCES</h2>
                </div>
      <div class="row">
        <div class="col-md-3">
          <article class="video-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-3">
          <article class="video-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 1.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-3">
          <article class="video-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 4.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-3">
          <article class="video-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 5.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-3">
          <article class="video-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 6.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-3">
          <article class="video-item">
            <figure>
             <img src="assets/images/IMG-20180504-WA0010.jpg"/>
            </figure>
          </article>
        </div>

        <div class="col-md-3">
          <article class="video-item">
            <figure>
             <img src="assets/images/IMG-20180504-WA0036.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-3">
          <article class="video-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 1.jpg"/>
            </figure>
          </article>
        </div>
      </div>
    </div>
  </section>
  )
}
