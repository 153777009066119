import React from 'react'

export default function Partners() {
  return (
    <>
    <section class="heading-page3 header-text" id="top">
    <div class="container">
      <div class="row">
        
      </div>
    </div>
  </section>
  <br/>
    <div class="partners text-center">
        
    </div>

</>
  )
}
