import React from 'react'

export default function PrevVideos() {
  return (
    <section class="section agenda" data-section="section5">
    <div class="container">
        <div class="section-heading2">
            <h2>PREVIOUS CONFERENCES VIDEOS</h2>
        </div>
      <div class="row">
        <div class="col-md-4">.
          <article class="agenda-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019.jpg"/>
            </figure>
          </article> 
        </div>
        <div class="col-md-4">
          <article class="agenda-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 1.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-4">
          <article class="agenda-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 4.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-4">
          <article class="agenda-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 5.jpg"/>
            </figure>
          </article>
        </div>
        <div class="col-md-4">
          <article class="agenda-item">
            <figure>
             <img src="assets/images/CONFERENCE 2019 6.jpg"/>
            </figure>
          </article>
        </div>
       </div>
    </div>
  </section>
  )
}
