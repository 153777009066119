import React from 'react'

export default function Speakers() {
  return (
    <>
    <section class="heading-page2 header-text" id="top">
    <div class="container">
      <div class="row">
        
      </div>
    </div>
  </section>
  <br/>
    <div class="speakers text-center">
        <h2 className="">  WATCH THE SPACE FOR UPDATES ON </h2>
        <h2 className="">SPEAKERS</h2>
    </div>

</>
  )
}
