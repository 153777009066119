import React from 'react'

export default function PrivacyPolicy() {
  return (
    <>
            <div className="terms-and-conditions py-5">
                <div className="container  text-lg-start py-3">
                    <div className="ms-lg-3 mt-3">
                        <h1 className="fw-800 text-white">Privacy Policy</h1>
                        <h5 className="text-white">This is the Privacy Policy for Intelli Africa Solutions, accessible from https://intelliafrica.solutions</h5>
                    </div>
                </div>
            </div>

            <section className="light-grey-bg">
                <div className="container pb-5 pt-3">
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        At Intelli Africa Solutions, accessible from https://intelliafrica.solutions one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Intelli Africa Solutions and how we use it.
                    </p>

                    <p className="text-green-2 fw-500 text-align-justify">
                        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                    </p>

                    <p className="text-green-2 fw-500 text-align-justify">
                        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Intelli Africa Solutions. This policy is not applicable to any information collected offline or via channels other than this website.
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Consent
                    </h4>

                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Information we collect
                    </h4>

                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        How we use your information
                    </h4>

                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        We use the information we collect in various ways, including to:
                        <ul>
                            <li className="custom-list-bullets">
                                Provide, operate, and maintain our website
                            </li>

                            <li className="custom-list-bullets">
                                Improve, personalize, and expand our website
                            </li>

                            <li className="custom-list-bullets">
                                Understand and analyze how you use our website
                            </li>

                            <li className="custom-list-bullets">
                                Develop new products, services, features, and functionality
                            </li>

                            <li className="custom-list-bullets">
                                Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes
                            </li>

                            <li className="custom-list-bullets">
                                Send you emails
                            </li>

                            <li className="custom-list-bullets">
                                Find and prevent fraud
                            </li>
                        </ul>
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Log Files
                    </h4>

                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Intelli Africa Solutions follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Cookies and Web Beacons
                    </h4>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Like any other website, Intelli Africa Solutions uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Google DoubleClick DART Cookie
                    </h4>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a className="text-green-2 fw-700 text-decoration-none" href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Our Advertising Partners
                    </h4>

                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Some of the advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
                        <ul>
                            <li className="custom-list-bullets">
                                Google <a className="text-green-2 fw-700 text-decoration-none" href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
                            </li>
                        </ul>
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Advertising Partners Privacy Policies
                    </h4>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        You may consult this list to find the Privacy Policy for each of the advertising partners of Intelli Africa Solutions.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Intelli Africa Solutions, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Note that Intelli Africa Solutions has no access to or control over these cookies that are used by third-party advertisers.
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Third Party Privacy Policies
                    </h4>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Intelli Africa Solutions’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites. What Are Cookies?
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Third Party Privacy Policies
                    </h4>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Under the CCPA, among other rights, California consumers have the right to:
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Request that a business delete any personal data about the consumer that a business has collected.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        GDPR Data Protection Rights
                    </h4>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                    </p>

                    <h4 className="text-green-2 fw-800  text-lg-start">
                        Children’s Information
                    </h4>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                    </p>
                    <p className="text-green-2 mt-3 fw-500 text-align-justify">
                        Intelli Africa Solutions does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </p>
                </div>

            </section>
    </>
  )
}
