import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsandConditions from './pages/TermsandConditions';
import './App.css';
import Index from './pages/Index';
import Agenda from './pages/Agenda';
import Partner from './pages/Partners';
import Speaker from './pages/Speakers';

function App() {
  const sectionIds = ['#about', '#what-we-do', '#team', '#products', '#contact'];

  return (
    <Router>
      <Routes>
      <Route path="/" element={<Index />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/partners" element={<Partner />} />
        <Route path="/speakers" element={<Speaker />} />
        <Route path="/termsandconditions" element={<TermsandConditions />} />
      </Routes>
     
    </Router>
  );
}

export default App;