import React, { useState } from 'react'

function WhatToExpect() {
  return (
    <>
         <section class="section why-us" data-section="section2">
            <div class="container">
            <div class="row">
                <div class="col-md-12">
                <div class="section-heading">
                    <h2>What to expect?</h2>
                </div>
                </div>
                <div class="col-md-12">
                    <section>
                        <div class="col-md-12 ">
                            <ul class="checkmark">
                                <li>Two full day conferencing amply packed with in-depth, thought-provoking presentations and panel sessions led by payments industry experts speaking from authentic experiences</li>
                                <li>Discussions on payment technological advances and cross-industry challenges which are shaping the payments landscape.</li>
                                <li>A journey in the customer’s shoes: Understanding payments from the customer’s vantage point. What does seamless payments look like from the other side of the lens.</li>
                                <li>Adapting to thrive and not just survive; defining strategies that can propel the industry forward. </li>
                                <li>Our well-rounded, comprehensive program encompasses a wide range of topics, ensuring that you gain the most relevant and valuable payment insights. </li>
                                <li>Define the missing part of the jigsaw fit; at the end of the conference, you will be able to identify the means to an end game; what is needed to accelerate digital payments growth for new and existing payment rails.  </li>
                                <li>An opportunity of networking with various players in the payments industry value chain.</li>
                            </ul>
                        </div>
                   
                    </section>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default WhatToExpect
