import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import TermsnConditions from '../components/container/TermsandConditions';


function TermsandConditions() {
  const sectionIds = ['#about', '#what-we-do', '#team', '#products', '#contact'];

  return (
    <>
      <Navbar sectionIds={sectionIds} />
      <TermsnConditions/>
      <Footer />
    </>
  );
}

export default TermsandConditions;