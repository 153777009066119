import React from 'react';
import Navbar from '../components/Navbar';
import MainBanner from '../components/MainBanner';
import About from '../components/About';
import WhatWeDo from '../components/WhatWeDo';
import Team from '../components/Team';
import Products from '../components/Products';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import ComingSoon from '../components/ComingSoon';
import WhatToExpect from '../components/WhatToExpect';
import Recap from '../components/Recap';
import WhoToAttend from '../components/WhoToAttend';
import Agenda from '../components/Agenda';
import PrevVideos from '../components/PrevVideos';
import Speakers from '../components/Speakers';
import Partners from '../components/Partners';

function Index() {
  const sectionIds = ['#about', '#what-we-do', '#team', '#products', '#contact'];

  return (
    <>
      <Navbar sectionIds={sectionIds} />
      <MainBanner/>
      <ComingSoon/>
      <About/>
      <WhatToExpect/>
      <WhoToAttend/>
      <Recap/>
      <Footer />
     </>
  );
}

export default Index;