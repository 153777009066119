import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Privacypolicy from '../components/container/PrivacyPolicy';

function PrivacyPolicy() {
  const sectionIds = ['#about', '#what-we-do', '#team', '#products', '#contact'];

  return (
    <>
      <Navbar sectionIds={sectionIds} />
      <Privacypolicy/>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;