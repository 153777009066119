import React, { useState, useEffect } from 'react';

function ComingSoon() {
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-07-21") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });


  return (
    <section className="section coming-soon" data-section="section3">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="continer centerIt">
              <div>
                <div className="counter">
                  <div className="days">
                    <div className="value">{timeLeft.days.toString().padStart(2, '0')}</div>
                    <span>Days</span>
                  </div>
                  <div className="hours">
                    <div className="value">{timeLeft.hours.toString().padStart(2, '0')}</div>
                    <span>Hours</span>
                  </div>
                  <div className="minutes">
                    <div className="value">{timeLeft.minutes.toString().padStart(2, '0')}</div>
                    <span>Minutes</span>
                  </div>
                  <div className="seconds">
                    <div className="value">{timeLeft.seconds.toString().padStart(2, '0')}</div>
                    <span>Seconds</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ComingSoon;
