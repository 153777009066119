import React from 'react';
import { Link } from 'react-router-dom';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { BsInstagram } from 'react-icons/bs';
import { BiLogoFacebook, BiLogoLinkedin } from 'react-icons/bi';


export default function Footer() {
  return (
    <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>21-24 July, Elephant Hills, Victoria Falls</p>
          <p> Copyright <i class="fa fa-copyright"></i> 2024  Zimswitch  </p>
        </div>
      </div>
    </div>
  </footer>
  );
}